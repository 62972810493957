.wrapper {
  padding: 56px 16px 16px;
  user-select: none;
  min-height: 500px;
  overflow: hidden;

  @media (max-width: 576px) {
    padding: 14px 0 0;
    position: relative;
  }
}

.headerMobileTitle {
  font-weight: 800;
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 26px;
  display: none;
  color: var(--content-primary-alt);
  text-align: center;
  margin-top: 10px;

  @media (max-width: 576px) {
    display: block;
  }
}

.spinner {
  margin: 100px 0;
}

.header {
  display: flex;
  align-items: center;

  @media (max-width: 992px) {
    margin-bottom: 20px;
  }

  @media (max-width: 576px) {
    padding: 0 16px;
  }

  &Image {
    width: 256px;
    min-width: 256px;
    height: 256px;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
    }

    @media (max-width: 992px) {
      position: absolute;
      opacity: 0.1;
      right: -50px;
    }
  }

  &Content {
    width: 100%;
    margin-left: 40px;

    @media (max-width: 992px) {
      margin-left: 0;
    }
  }
}

.tab {
  &Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    user-select: none;

    &Inner {
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--content-thirdary-alt);
      border-radius: 40px;
      padding: 2px;
    }

    &.tabSecondary {
      .tabWrapperInner {
        background-color: var(--background-secondary);
      }
    }
  }

  &Item {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border-radius: 40px;
    padding: 10px 24px;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    width: calc(100% + 20px);
    color: var(--content-primary-alt);

    @media (max-width: 576px) {
      padding: 10px;
      font-size: 12px;
    }

    &Active {
      background: var(--background-primary);
      box-shadow: 0 2px 15px rgba(9, 17, 38, 0.1);
      color: var(--content-primary);
    }

    .tabSecondary & {
      color: var(--content-primary);
    }
  }

  @media (max-width: 576px) {
    &Wrapper {
      justify-content: flex-start;
      overflow: auto;
      padding-left: 12px;
      padding-right: 12px;

      &:after {
        content: '';
        display: block;
        width: 12px;
        min-width: 12px;
        height: 12px;
      }
    }
  }
}

.category {
  font-size: 14px;
  line-height: 17px;
  color: var(--content-secondary-alt);
  margin-bottom: 14px;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: var(--content-primary-alt);
  margin-bottom: 14px;
}

.tags {
  display: flex;
  margin-bottom: 14px;
  row-gap: 10px;
  flex-wrap: wrap;
}

.info {
  display: flex;
  align-items: flex-start;
  overflow: auto;
  white-space: nowrap;
  scrollbar-width: none;
  margin-bottom: 1rem;
  -ms-overflow-style: none;

  @media (max-width: 576px) {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;

    &:after {
      content: '';
      width: 8px;
      min-width: 8px;
      height: 8px;
      display: block;
    }
  }
}

.content {
  background: var(--background-primary);
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 32px;

  @media (max-width: 576px) {
    padding: 10px;
  }
}

.image {
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #ededed;
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.button {
  &List {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: space-between;
    margin-bottom: 32px;

    @media (max-width: 576px) {
      justify-content: center;
    }
  }

  &Block {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;

    @media (max-width: 576px) {
      justify-content: center;
    }
  }
}

.listTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: var(--content-primary);
  margin-bottom: 20px;

  @media (max-width: 576px) {
    margin-left: 20px;
  }
}

.list {
  width: 100%;

  &Item {
    width: 100%;
    background: var(--background-secondary);
    border-radius: 8px;
    padding: 20px;
    display: flex;
    align-items: center;

    &Number {
      border: 2px solid var(--brand-secondary);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: var(--content-primary);
      width: 30px;
      min-width: 30px;
      height: 30px;
      margin-right: 12px;
    }

    &Title {
      font-weight: bold;
      font-size: 15px;
      line-height: 160%;
      color: var(--brand-secondary);
    }

    &Content {
      font-size: 14px;
      line-height: 180%;
      color: var(--content-primary);
      width: 100%;

      p {
        margin: 0;

        +p {
          margin-top: 12px;
        }
      }
    }

    +.listItem {
      margin-top: 16px;
    }
  }
}

.dateModal {
  padding: 24px;
  border-radius: 26px;
  overflow: hidden;
  position: relative;

  &Title {
    font-weight: bold;
    font-size: 23px;
    line-height: 140%;
    text-align: center;
    color: var(--content-primary);
    margin-bottom: 24px;
  }

  &Button {
    margin: 0 6px;

    &List {
      display: flex;
      margin-left: -6px;
      margin-right: -6px;
    }
  }

  &FeeDate {
    text-align: center;
    margin-bottom: 24px;
  }

  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.7);
    user-select: none;
    margin: 0;
  }
}

.paymentModal {
  padding: 24px;

  &Title {
    font-weight: 800;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: var(--brand-secondary);
    margin-bottom: 16px;
  }

  &ButtonList {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 24px;

    >* {
      &:not(:first-child) {
        margin-left: 16px;
      }
    }
  }

  &Info {
    text-align: center;
    margin-bottom: 24px;

    &Change {
      color: var(--brand-secondary);
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
      user-select: none;
    }
  }
}

.table {
  border: solid var(--background-line);
  border-width: 1px 0;

  &Header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--background-line);

    .tableCell {
      padding: 12px 8px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: var(--content-secondary);
    }
  }

  &Cell {
    flex: 3;
    width: 150px;
    font-size: 14px;
    line-height: 140%;
    color: var(--content-primary);
    cursor: pointer;
    user-select: text;

    &:nth-child(1) {
      flex: 2;
    }

    &Right {
      text-align: right;
    }

    &Date {
      width: 100px;
      flex: 100px;
    }

    &Status {
      width: 100px;
      flex: 3 !important;
    }
  }

  &Row {
    display: flex;
    align-items: center;
  }

  &Body {
    display: block;
    width: 100%;

    .tableCell {
      padding: 12px 8px;
    }
  }
}

.feeInfo {
  text-align: center;
  margin-bottom: 24px;
}

.document {
  display: block;

  &Error {
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid #ededed;
    border-radius: 8px;

    &Title {
      font-size: 16px;
      margin: 0 auto 24px;
      max-width: 400px;
      text-align: center;
    }
  }
}

.report {
  &Title {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: var(--content-primary);
    margin-bottom: 24px;
  }

  &Table {
    width: 100%;

    &Header {
      width: 100%;

      &Row {
        border: solid var(--background-line);
        border-width: 1px 0;
        padding: 12px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &Column {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: var(--content-secondary);
      }
    }

    &Body {
      width: 100%;

      &Row {
        padding: 12px 8px;
        border-bottom: 1px solid var(--background-line);
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &Column {
        font-size: 14px;
        line-height: 17px;
        text-align: right;
        color: var(--content-primary);
      }
    }
  }

  &File {
    display: flex;
    align-items: center;
    text-align: left;

    &Icon {
      width: 32px;
      height: 32px;
      background: var(--brand-secondary);
      border-radius: 6px;
      padding: 4px;
      color: var(--content-primary-alt);
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 20px;
        height: 20px;
        display: block;
      }
    }

    &Content {
      margin-left: 10px;
    }

    &Name {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      color: var(--content-primary);
    }

    &Size {
      font-weight: 600;
      font-size: 11px;
      line-height: 140%;
      color: var(--content-thirdary);
    }
  }
}


.branches {
  display: block;
  padding: 1rem;

  &Title {
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    color: white;
    margin: 0 auto 24px;
    text-align: center;
    max-width: 760px;
  }

  &Action {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
}

.workers {
  background: rgb(240, 240, 240);
  display: block;
  margin: 20px;
  margin-top: 5rem;
  padding: 20px;
  border-radius: 8px;
}

.empty {
  text-align: center;
}

.annual {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;

  &Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &Input {
    width: 100%;
  }
}